.card {
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

$margin: 8px;
.cardContainer {
  position: relative;
  background-color: #ebebeb;
  margin: $margin !important;

}

.titleWrapper {
  word-wrap: break-word;
}
