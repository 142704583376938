.youtubeIconWrp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  height: max-content;
  left: calc(50% - 30px);
  top: calc(50% - 50px);
  position: absolute;
}

.imgItem {
  &:hover {
    .youtubeIcon {
      color: #ff0000;
      cursor: pointer;
    }
  }

  .youtubeIcon {
    font-size: 60px;
    color: #282828;
    opacity: 0.8;
  }
}
