.applyCaption {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  //position: absolute;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 100%;
  //bottom: 0px;
  //left: 0px;
  //right: 0;

  &.overlay {
    position: fixed;
    top: auto;
    bottom: 0;
    z-index: 10;
    -webkit-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    transition: all 600ms ease;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    //max-width: 1200px;

    .actionButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      color: #fff;
      .greenButton {
        background-color: #26a20f;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #207f0e;
        text-transform: uppercase;
        transition: 0.3s;
        color: white;

        padding-left: 18px;
        padding-right: 18px;
        margin: 0 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #207f0e;
        }
      }

      .redButton {
        background-color: #d7000e;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #e01a1a;
        text-transform: uppercase;
        transition: 0.3s;
        color: white;

        padding-left: 18px;
        padding-right: 18px;
        margin: 0 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #98040e;
        }
      }
      .backButton {
        min-width: 88px;
        padding: 5px;
        height: 38px;
        border-radius: 4px;
        background-color: none;
        border: 1px solid darkgrey;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        transition: 0.3s;
        &:hover {
          opacity: 1.2;
        }
      }
    }
  }
}
.hiringMapJob {
  margin-top: 2.5rem;
}

.clientInformation {
  .body {
    margin-top: 0.75rem;

    h1,
    h2,
    h3 {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }

    li > * {
      padding: 0;
      margin: 0;
    }
  }
}
.clientPosts {
  margin-bottom: 40px;
  margin-top: 2rem;
}
.subtitle {
  font-size: 1.5rem;
  font-family: Arial Black, sans-serif;
  text-transform: uppercase;
  margin: 0.75rem 0;
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 768px) {
  .applyCaption {
    padding: 5px 10px;

    .container {
      label {
        font-size: 16px;
      }
      .actionButtons {
        margin: 0;
        .greenButton {
          font-size: 17px;
          margin: 0 10px;
        }
        .redButton {
          font-size: 17px;
          margin: 0 10px;
        }
        .backButton {
          font-size: 14px;
          padding: 0 10px;
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .applyCaption {
    .container {
      flex-direction: column;
      .actionButtons {
        width: 100%;
        .redButton {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .companyButton {
    &__green {
      margin-top: 12px;
    }
  }
  .applyCaption {
    .actionButtons {
      .redButton {
        margin: 0 10px;
        height: auto;
      }
      .backButton {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .applyCaption {
    .actionButtons {
      .redButton {
        font-size: 17px;
        padding: 0px 6px;
      }
      .backButton {
        padding: 0 6px;
      }
    }
  }
}
